<template>
  <div class="d-flex flex-nowrap align-center">
    <document-ref-icon
      :visible="showIcon"
      icon-name="i.BusinessDivisions"
      :mixed="mixed"
      :x-small="_iconProps.xsmall"
      :small="_iconProps.small"
      :normal="_iconProps.normal"
      :large="_iconProps.large"
      :x-large="_iconProps.xlarge"
    />
    <div class="d-flex flex-column flex-nowrap">
      <div class="d-flex flex-column flex-nowrap">
        <span class="nowrap">{{name}}</span>
        <div
          class="d-flex flex-row ref-detail"
          v-if="innerOptions.showDetail"
        >
          <document-name-ref
            v-if="_companyProps.visible && (!_companyProps.hideIfSameAsDivision || id !== companyId)"
            :class="{'smallText': _companyProps.small}"
            class="nowrap ref-detail"
            :id="companyId"
            :cache-type="companyCacheType"
          />
        </div>
      </div>
      <slot name="doc-type" />

    </div>
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  computed: {
    companyCacheType () {
      return ClientCache.CacheType.BusinessDivisionRef
    },
    companyId () {
      return this.cache?.doc?.companyId
    },
    name () {
      return this.id ? this.cache?.doc?.name : this.$t('t.None')
    },
    _companyProps () {
      const _ = Object.assign({
        visible: true,
        small: true,
        hideIfSameAsDivision: true
      }, this.companyProps)

      return _
    },
    innerOptions () {
      return {
        showDetail: this.options?.showDetail
      }
    },
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: false,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    }
  },
  data () {
    return {
      cacheType: ClientCache.CacheType.BusinessDivisionRef
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    companyProps: Object,
    iconProps: Object,
    mixed: Boolean,
    showIcon: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.smallText
  font-size 0.75em
</style>
